import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import Layout from "src/component/layout/layout"
import TabsCaseStudies from "src/component/common/tabs_casestudies"
import { Seo } from "src/component/common/seo"

export default function Casestudies({ children }) {
  const location = useLocation()

  // URLのパスセグメントを取得
  const pathSegments = location.pathname.split("/")
  const currentTab = pathSegments[pathSegments.length - 1] // URLの最後の部分を取得

  // 有効なタブのリスト
  const validTabs = [
    "data-structuring",
    "predictive-ai",
    "analytical-ai",
    "optimization-ai",
    "gx-solutions",
    "geospatial-data"
  ]

  // URLから取得した値が有効なタブかチェック、無効な場合は "food" に設定
  const initialTab = validTabs.includes(currentTab) ? currentTab : "data-structuring"

  const [activeTab, setActiveTab] = useState(initialTab)
  const [fade, setFade] = useState(true)

  // location.pathname が変わった時に activeTab を更新
  useEffect(() => {
    const newPathSegments = location.pathname.split("/")
    const newCurrentTab = newPathSegments[newPathSegments.length - 1]
    const newInitialTab = validTabs.includes(newCurrentTab) ? newCurrentTab : "data-structuring"

    
    console.log("Updated Tab:", newInitialTab) 
    setActiveTab(newInitialTab) // activeTab を更新
  }, [location.pathname]) 

  // スクロールアニメーションの処理
  useEffect(() => {
    let js_scroll = document.getElementsByClassName("js-scroll")
    const scroll = () => {
      for (let i = 0; i < js_scroll.length; i++) {
        if (
          window.pageYOffset + window.innerHeight / 1.5 >
          window.pageYOffset + js_scroll[i].getBoundingClientRect().top
        ) {
          js_scroll[i].classList.add("is-active")
        }
      }
    }
    scroll()
    window.addEventListener("scroll", () => {
      scroll()
    })
  }, [])

  return (
    <>
      <Layout>
        <div className="introduction">
          <div className="introduction__inner c-inner-primary">
            <h1 className="introduction__title title-bold">CASE STUDIES</h1>
            <div className="introduction__item__catchcopy title-bold-catchcopy c-pc js-scroll">
              <div>
                <div>
                  <span>ケーススタディ</span>
                </div>
              </div>
            </div>
            <div className="introduction__item__catchcopy title-bold-catchcopy c-sp js-scroll">
              <div>
                <div>
                  <span>ケーススタディ</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* タブコンポーネント */}
        <TabsCaseStudies activeTab={activeTab} setActiveTab={setActiveTab} />

        {/* 子コンテンツ */}
        {children}

        {/* 再度タブコンポーネントを表示 */}
        <TabsCaseStudies activeTab={activeTab} setActiveTab={setActiveTab} />
      </Layout>
    </>
  )
}

export function Head() {
  return (
    <Seo
      title="CASE STUDIES"
      description=""
      pathname=""
    />
  )
}
