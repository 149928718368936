import React from "react"
import { Seo } from "src/component/common/seo"
import Casestudies from "src/component/layout/casestudies"
import Button from "src/component/common/button"
import { StaticImage } from "gatsby-plugin-image"
import GxSolutionsCasestudyList from "src/component/list/gx-solutionsCasestudyList"

export default function GxSolutions() {
    return (
        <>
         <Casestudies>
          <div className="gx-solutions inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_gx-solutions.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_gx-solutions-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>GXソリューション</p>
                                  <span>GX SOLUTIONS</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                  エネルギー・行動・環境データをAIで統合分析し、データドリブンなGXを推進。
                                  <br />
                                    環境負荷を最小化し、脱炭素社会への移行をテクノロジーで加速。
                                    <br className="c-pc" />
                                    企業の環境価値と競争力を向上させます。
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>多様なデータを紡ぎ、<br />環境価値を生み出す<br className="c-sp" />GXソリューションへ</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>CO₂排出量のAI可視化</h3>
                                 <p>
                                    エネルギー消費データをAIで分析し、CO₂削減の最適な方法を具体的に提案。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIで再エネ予測最適化</h3>
                                 <p>
                                    顧客データを基に、個々のニーズに合わせた商品やサービスの提案を行い、満足度と売上を向上。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>カーボン排出AI可視化</h3>
                                 <p>
                                    製品・サービスのCO₂排出をAIで可視化し、削減ポイントを示し脱炭素経営を支援。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                  <h3>AIでオフィス省エネ化</h3>
                                 <p>
                                    建物内の環境データをAI解析し、省エネと運用コスト削減を同時に実現。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIで促す循環型社会</h3>
                                 <p>
                                    消費者行動データを解析し、循環型社会への意識と行動を促す施策を提案。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <GxSolutionsCasestudyList />
                    </div>
                </section>
              </div>
          </Casestudies>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="GX SOLUTIONS"
            description=""
            pathname=""
        />
    )
}